import { APICore, API_URL } from './apiCore';
import { FileType } from '../../interfaces/FileType';
import { bool } from 'yup';

const api = new APICore();
// const API_URL = 'http://localhost:5000/api'

interface GetProductTransfersParams {
    page?: number;
    search?: string;
    status?: string;
    clientId?: string;
    companyId?: string;
    productType?: string;
    isActive?: string;
    action?: string;
    fromSubmissionDate?: string;
    toSubmissionDate?: string;
    fromCloseDate?: string;
    fromAgentSignatureDate?: string;
    toAgentSignatureDate?: string;
    fromLastChangeDate?: string;
    toLastChangeDate?: string;
    toCloseDate?: string;
    employmentStatus?: string;
}
const getProductTransfers = (params: GetProductTransfersParams) => {
    const relativeUrl = '/product-transfers/operation';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const exportProductTransfers = (params: GetProductTransfersParams) => {
    const relativeUrl = '/product-transfers/operation/export';
    return api.getFile(`${API_URL}${relativeUrl}`, params);
};

const uploadProductTransfers = (params: { file: Blob }) => {
    const relativeUrl = '/product-transfers/operation/import';
    return api.createWithFile(`${API_URL}${relativeUrl}`, params);
};

const uploadFileToProductTransfer = (id: string, params: { file: File }) => {
    const relativeUrl = '/product-transfers/' + id + '/upload-file';
    return api.createWithFile(`${API_URL}${relativeUrl}`, params);
};

const editProductTransfer = (id: string, params: any) => {
    const relativeUrl = '/product-transfers/' + id;

    return api.update(`${API_URL}${relativeUrl}`, params);
};

const updateProductTransfer = (id: string, params: any) => {
    const relativeUrl = `/product-transfers/${id}/operation`;

    return api.update(`${API_URL}${relativeUrl}`, params);
};

const resubmitB2 = (productId: string) => {
    const relativeUrl = `/products/${productId}/submitB2Form`;

    return api.create(`${API_URL}${relativeUrl}`, {});
};

const archiveSigningDocument = (id: string) => {
    const relativeUrl = `/product-transfers/${id}/archive-signed-document`;

    return api.update(`${API_URL}${relativeUrl}`, {});
};

const getEditProductTransferData = (id: string) => {
    const relativeUrl = `/product-transfers/${id}/edit`;
    return api.get(`${API_URL}${relativeUrl}`);
};

const getProductTransferCompanies = () => {
    const relativeUrl = `/product-transfers/companies`;
    return api.get(`${API_URL}${relativeUrl}`);
};

const recalculateSavings = (params: any) => {
    const relativeUrl = `/product-transfers/recalculate-all`;
    return api.create(`${API_URL}${relativeUrl}`, params);
};

const getSigningUrl = (productTransferId: string, preview?: boolean) => {
    const relativeUrl = `/product-transfers/${productTransferId}/signing-url`;
    if (preview) return api.getFile(`${API_URL}${relativeUrl}`, { preview });
    // return api.get(`${API_URL}${relativeUrl}`,{joinAndTransfer:true, preview:true});
    else return api.get(`${API_URL}${relativeUrl}`);
};

const getMultipleDocsSigningUrl = (clientId: string, pensionId?: string, preview?: boolean) => {
    const relativeUrl = `/product-transfers/signing-url`;
    if (preview)
        return api.getFile(`${API_URL}${relativeUrl}`, {
            preview,
            pensionId,
            clientId,
        });
    // return api.get(`${API_URL}${relativeUrl}`,{joinAndTransfer:true, preview:true});
    else return api.get(`${API_URL}${relativeUrl}`, { clientId, pensionId });
};

const mailProductTransfers = (productTransferIds: string[]) => {
    const relativeUrl = `/product-transfers/send-signed-documents-to-company`;
    return api.create(`${API_URL}${relativeUrl}`, { productTransferIds });
};

export {
    getProductTransferCompanies,
    archiveSigningDocument,
    getEditProductTransferData,
    getSigningUrl,
    getProductTransfers,
    recalculateSavings,
    editProductTransfer,
    getMultipleDocsSigningUrl,
    updateProductTransfer,
    exportProductTransfers,
    uploadProductTransfers,
    mailProductTransfers,
    uploadFileToProductTransfer,
    resubmitB2,
};
