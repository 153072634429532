import React from 'react';
import RoutesHandler from './routes/Routes';
import { configureFakeBackend } from './helpers';
import { AppProvider } from './context/AppContext';
import { UnansweredClientsProvider } from './context/UnansweredClientsContext';
import { ChatAndTaskCountProvider } from './context/ChatAndTaskCountContext';
// Themes

// For Default import Default.scss
import './assets/scss/Default.scss';
import { EnumsProvider } from './context/EnumsContext';
// For Dark import Default-Dark.scss
// import './assets/scss/Default-Dark.scss';

// For Saas import Saas.scss
// import './assets/scss/Saas.scss';
// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';
// For Modern dark demo import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Creative-Dark.scss
// import './assets/scss/Creative-Dark.scss';

// For Purple demo import Purple.scss
// import './assets/scss/Purple.scss';
// For Purple dark demo import Purple-Dark.scss
// import './assets/scss/Purple-Dark.scss';

// For Material demo import Material.scss
// import './assets/scss/Material.scss';
// For Material dark demo import Material-Dark.scss
// import './assets/scss/Material-Dark.scss';

// configure fake backend
configureFakeBackend();

const App = () => {
    return (
        <AppProvider>
            <EnumsProvider>
                <UnansweredClientsProvider>
                    <ChatAndTaskCountProvider>
                        <RoutesHandler />
                    </ChatAndTaskCountProvider>
                </UnansweredClientsProvider>
            </EnumsProvider>
        </AppProvider>
    );
};

export default App;
