import React from 'react';
import './InputWrapper.scss';

interface inputWrapperProps {
    children: React.ReactNode;
    label?: string;
    status?: { text: string; class?: string };
    wrapperClass?: string;
    inputName?: string;
}

const InputWrapper = ({ children, label, status, wrapperClass, inputName }: inputWrapperProps) => {
    return (
        <div className={`inputWrapper ${wrapperClass ? wrapperClass : ''}`}>
            {label && (
                <label className={`form-label`} htmlFor={inputName}>
                    {label}
                </label>
            )}

            {children}

            {status && <span className={`input-status ${status.class ? status.class : ''}`}>{status.text}</span>}
        </div>
    );
};

export default InputWrapper;
