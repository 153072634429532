import React from 'react';
import { downloadFileFromAPI } from '../utils/downloadFileFromAPI';
import { getClientFilesZip } from '../utils/api/clients';
import { Button } from 'react-bootstrap';
import globalErrorHandler from '../utils/globalErrorHandler';
interface Props {
    clientId: string;
    clientName: string;
    fileClassifications: string[];
}
const DonwloadClientFilesButton = ({ clientId, clientName, fileClassifications }: Props) => {
    const handleDownloadFiles = async () => {
        try {
            const result = await getClientFilesZip(clientId, fileClassifications);
            downloadFileFromAPI(result, `${clientName} - Files.zip`, 'zip');
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };
    return (
        <Button variant="primary" onClick={handleDownloadFiles} className="btn btn-primary">
            Download Files
        </Button>
    );
};

export default DonwloadClientFilesButton;
