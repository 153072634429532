import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Spinner from './Spinner';
import { FileType } from '../interfaces/FileType';

interface FileUploaderProps {
    onFileUpload?: (file: FileType) => void;
    onRemoveFile?: (file: FileType) => void;
    showPreview?: boolean;
    currentImageUrl?: string;
}

const FileUploader = (props: FileUploaderProps) => {
    const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);

    /**
     * Handled the accepted files and shows the preview
     */
    const handleAcceptedFiles = (files: FileType[]) => {
        if (props.showPreview) {
            (files || []).map((file) =>
                Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: formatBytes(file.size),
                    isLoading: true,
                }),
            );
        }

        onFileUpload(files);
    };

    const onFileUpload = async (files: FileType[]) => {
        const allFiles = [...selectedFiles];
        allFiles.push(...files);
        setSelectedFiles(allFiles);

        for (const file of files) {
            // await wait(2000);
            if (props.onFileUpload) await props.onFileUpload(file);
            file.isLoading = false;
            setSelectedFiles([...allFiles]);
        }

        setSelectedFiles([...allFiles]);
    };

    /**
     * Formats the size
     */
    const formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    /*
     * Removes the selected file
     */
    const removeFile = (fileIndex: number) => {
        const newFiles = [...selectedFiles];
        const removedFile = newFiles.splice(fileIndex, 1)[0];
        setSelectedFiles(newFiles);
        if (props.onRemoveFile) props.onRemoveFile(removedFile);
    };

    return (
        <>
            <Dropzone {...props} onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                        {props.currentImageUrl ? (
                            <img src={props.currentImageUrl} alt="Current Image" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        ) : (
                            <div className="dz-message" {...getRootProps()}>
                                <i className="h3 text-muted dripicons-cloud-upload"></i>
                                <h4>Drop files here or click to upload.</h4>
                            </div>
                        )}

                        <div className="needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />
                        </div>
                    </div>
                )}
            </Dropzone>

            {props.showPreview && (
                <div className="dropzone-previews mt-3" id="uploadPreviewTemplate">
                    {(selectedFiles || []).map((f, i) => {
                        return (
                            <Card className="mt-1 mb-0 shadow-none border" key={i + '-file'}>
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            {f.preview ? (
                                                <img data-dz-thumbnail="" className="avatar-lg rounded bg-light" alt={f.name} src={f.preview} />
                                            ) : (
                                                <div className="avatar-lgm">
                                                    <span className="avatar-title bg-primary rounded">{f.type.split('/')[1]}</span>
                                                </div>
                                            )}
                                            {f.isLoading ? (
                                                <span className="spinner-screen">
                                                    <span className="inner">
                                                        <Spinner className="m-2" size={'lg'} color={'primary'} />
                                                    </span>
                                                </span>
                                            ) : null}
                                        </Col>

                                        <Col className="ps-0">
                                            <Link to="#" className="text-muted fw-bold">
                                                {f.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                        <Col className="text-end">
                                            <Link to="#" className="btn btn-link btn-lg text-muted shadow-none">
                                                <i className="dripicons-cross" onClick={() => removeFile(i)}></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}
                </div>
            )}
        </>
    );
};

FileUploader.defaultProps = {
    showPreview: true,
};

export default FileUploader;
