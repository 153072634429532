import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { UserProfileContext } from '../../../context/userProfileContext';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { useQuery } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { editClient } from '../../../utils/api/clients';
import ordinal from '../../../utils/oridnalSuffix';
import { APICore } from '../../../utils/api/apiCore';
import { Tooltip } from '@mui/material';
import globalErrorHandler from '../../../utils/globalErrorHandler';

const ModuleTabs = () => {
    const query = useQuery();
    const navigate = useNavigate();

    const { profileContextData, setProfileContextData } = useContext(UserProfileContext);

    const [modules, setModules] = useState<any>(profileContextData.modules);
    const [modulesOrder, setModuleOrder] = useState<string[]>([]);
    const modulesOrderObject = profileContextData.client?.modulesOrder || [];
    const [orderChangedByDrag, setOrderChangedByDrag] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const loggedInUser = new APICore().getLoggedInUser().user;
    const isUserAllowedToDrag = loggedInUser.roles.includes('support') || loggedInUser.isAdmin;

    useEffect(() => {
        // The problem is that the modules order object includes the "isRelevant" field inside it
        const parseModuleOrder = () => {
            // And we want to only keep a list of ordered strings
            let orderedStringArrayOfModulesNames;
            if (modulesOrderObject.length > 0) {
                orderedStringArrayOfModulesNames = modulesOrderObject.map((moduleData) => {
                    const name = moduleData.moduleName;
                    if (name === 'bankFees') return 'bankFees';
                    return name + 's';
                });
                setModuleOrder(orderedStringArrayOfModulesNames);
            } else {
                orderedStringArrayOfModulesNames = Object.keys(modules);
                setModuleOrder(orderedStringArrayOfModulesNames);
            }
        };
        parseModuleOrder();
    }, [modules]);

    useEffect(() => {
        const addIsRelevantStatusToModulesObject = () => {
            // This function moves the "isRelevant" field from the modules order object to the modules object
            const modulesDataObject = { ...modules };
            if (modulesOrderObject && modulesOrderObject.length > 0) {
                Object.keys(modulesDataObject).forEach((key) => {
                    const matchingModule = modulesOrderObject.find((module: any) => key.includes(module.moduleName));
                    if (matchingModule) {
                        modulesDataObject[key].isRelevant = matchingModule.order !== 0;
                    } else {
                        modulesDataObject[key].isRelevant = true; // Default to true if no matching module is found
                    }
                });
            } else {
                Object.keys(modulesDataObject).forEach((key) => {
                    modulesDataObject[key].isRelevant = true;
                });
            }
            setModules(modulesDataObject);
        };
        addIsRelevantStatusToModulesObject();
    }, []);

    const matchOrderedStringArrayToModulesOrderObject = () => {
        // For sending it to the server with editClient
        const modulesOrderObject = modulesOrder.map((moduleName: string) => ({
            moduleName: moduleName === 'bankFees' ? 'bankFees' : moduleName.slice(0, -1),
            isRelevant: modules[moduleName].isRelevant,
        }));
        return modulesOrderObject;
    };

    useEffect(() => {
        if (!orderChangedByDrag) return;
        const editClientModuleOrder = async () => {
            try {
                const modulesOrder = matchOrderedStringArrayToModulesOrderObject();
                await editClient(profileContextData?.client._id, { modulesOrder });
                setProfileContextData((prev: any) => ({
                    ...prev,
                    client: {
                        ...prev.client,
                        modulesOrder,
                    },
                }));
            } catch (err) {
                globalErrorHandler(err);
            }
        };
        editClientModuleOrder();
        setOrderChangedByDrag(false);
    }, [modulesOrder, orderChangedByDrag]);

    const onDragEnd = (result: any) => {
        if (result.destination && modulesThatAreNotDraggable.includes(modulesOrder[result.destination.index])) return;
        if (!result.destination) return;

        const sourceModuleName = modules[modulesOrder[result.source.index]].data.moduleName;
        const position = result.destination.index + 1;
        const isConfirmed = window.confirm(`Are you sure you want to make ${camelCaseToText(sourceModuleName)} the ${ordinal(position)} module?`);
        if (!isConfirmed) return;

        const newModuleOrder = Array.from(modulesOrder);
        const [removed] = newModuleOrder.splice(result.source.index, 1);
        newModuleOrder.splice(result.destination.index, 0, removed);

        setModuleOrder(newModuleOrder);
        setOrderChangedByDrag(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    const selectTab = (selected: any) => {
        navigate(`/clients/profile?clientId=${query.get('clientId')}&module=${selected?.data?.moduleName}`);
        setSelectedTab(selected?.data?.moduleName);
        setProfileContextData((prev: any) => ({
            ...prev,
            selectedModule: selected,
            currentRound: selected.data.rounds[0],
        }));
        console.log(selected);
    };

    useEffect(() => {
        setSelectedTab(profileContextData.selectedModule?.data?.moduleName);
    }, [profileContextData.selectedModule]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="modules" direction="horizontal">
                {(provided) => (
                    <div className="module-tabs-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
                        {modulesOrder.map((name, index) =>
                            modules[name] ? (
                                <Draggable
                                    key={name}
                                    draggableId={name}
                                    isDragDisabled={modulesThatAreNotDraggable.includes(name) || !isUserAllowedToDrag}
                                    index={index}
                                    disableInteractiveElementBlocking={true}
                                >
                                    {(provided) => (
                                        <Button
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            disabled={!modules[name].permittedToView}
                                            variant={`${modules[name].isRelevant ? 'primary' : 'outline-warning'}`}
                                            onClick={() => selectTab(modules[name])}
                                            className={
                                                modules[name].data.moduleName === selectedTab && modules[name].isRelevant
                                                    ? 'selected'
                                                    : modules[name].data.moduleName === selectedTab && !modules[name].isRelevant
                                                      ? 'btn-warning text-black box-shadow-lg'
                                                      : ''
                                            }
                                        >
                                            {modules[name].isRelevant ? (
                                                camelCaseToText(modules[name].data.moduleName)
                                            ) : (
                                                <Tooltip title="This module was marked as irrelevant by the client" arrow placement="top">
                                                    <span>{camelCaseToText(modules[name].data.moduleName)}</span>
                                                </Tooltip>
                                            )}
                                        </Button>
                                    )}
                                </Draggable>
                            ) : null,
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default ModuleTabs;

const modulesThatAreNotDraggable = ['onboardings', 'pensions'];
