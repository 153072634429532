import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Table from '../../../components/Table';
import { addCommas } from '../../../utils/addCommas';
import { PensionProduct, PensionProductTypes } from '../../../interfaces/PensionProduct';
import { createProduct, getFullHanmaka, getProducts, getProductsForPensionZoom, removeProduct, updateProducts } from '../../../utils/api/products';
import MoneyColumn from '../../../components/MoneyColumn';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { FormInput } from '../../../components';
import { editProductTransfer, getMultipleDocsSigningUrl, recalculateSavings } from '../../../utils/api/productTransfers';
import { ProductTransfer, TransferStatuses } from '../../../interfaces/ProductTransfer';
import { SignedDocument } from '../../../interfaces/SignedDocument';
import DualSigningButton from '../../../components/dualSigningButton/DualSigningButton';
import CreateNewPensionProduct from '../CreateNewPensionProduct';
import { NewPensionProduct } from '../../../interfaces/NewPensionProduct';
import './Products.scss';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';
import { UserProfileContext } from '../../../context/userProfileContext';
import EditProductTransfer from '../../product/EditProductTransfer';
import { archiveSignature } from '../../../utils/api/signatures';
import { getCompanyName } from '../../../utils/getCompanyName';
import { formatDate } from '../../../utils/formatDate';
import { downloadCubeForm } from '../../../utils/api/cubes';
import { Company } from '../../../interfaces/Company';
import CubesModal from '../cubes/CubesModal';
import { Deposit } from '../../../interfaces/Deposit';
import { fetchJotformSubmissionManually } from '../../../utils/api/messages';
import SendCubesToEmployerButton from '../../../components/SendCubesToEmployerButton';
import { Pension } from '../../../interfaces/Pension';
import { PENSION_ZOOM_WEBSITE_URL, getUserFromCookie } from '../../../utils/api/apiCore';

import { useTranslation } from 'react-i18next';
import isProductHandledByBetter from '../../../utils/isProductHandledByBetter';
import globalErrorHandler from '../../../utils/globalErrorHandler';
const MAX_SHOWN_PRODUCTS = 6;

const Products = () => {
    const { t } = useTranslation('product');
    const { profileContextData } = useContext(UserProfileContext);
    const { currentRound, client } = profileContextData;
    const pension = currentRound as Pension;
    const navigate = useNavigate();
    const mounted = useRef(false);

    const [products, setProducts] = useState<PensionProduct[]>([]);
    const [showNewProduct, setShowNewProduct] = useState(false);
    const [isLoadingUpdateCalc, setIsLoadingUpdateCalc] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState<PensionProduct[]>([]);
    const [hanmakaSignedDocument, setHanmakaSignedDocument] = useState<SignedDocument>();
    const [transfersSignedDocument, setTransfersSignedDocument] = useState<SignedDocument>();
    const [showCubesForm, setShowCubesForm] = useState(false);
    const [hasProductsToTransfer, setHasProductsToTransfer] = useState(false);
    const [productsDataForPensionZoomIsAvailable, setProductsDataForPensionZoomIsAvailable] = useState(false);
    const [updatingProductsLoader, setUpdatingProductsLoader] = useState(false);
    const [pensionZoomWindow, setPensionZoomWindow] = useState<Window | null>(null);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const result = await getProductsForPensionZoom(client?._id);
                if (result && result.pensionProducts.length > 0) {
                    setProductsDataForPensionZoomIsAvailable(true);
                }
            } catch (err) {
                console.log('zoom products api error', err);
            }
        };

        if (!productsDataForPensionZoomIsAvailable) {
            getProducts();
        }
    }, [client?._id]);

    useEffect(() => {
        mounted.current = true;
        if (mounted) {
            handleProducts();
        }

        return () => {
            mounted.current = false;
        };
    }, [pension]);

    useEffect(() => {
        let count: number = products.filter((p: PensionProduct) => {
            if (p.productTransfer && p.productTransfer.status === TransferStatuses.StartTransfer) {
                return p.productTransfer && p.productTransfer.isActive && p.productTransfer.employmentStatus !== 'selfEmployed';
            } else {
                const selfEmployedDeposits = p.deposits.list.filter((d: Deposit) => d.amounts.selfEmployed && !d.salary);
                if (selfEmployedDeposits.length === p.deposits.list.length) return false;

                return p.isActiveByDeposits && p.employmentStatus !== 'selfEmployed';
            }
        }).length;

        setShowCubesForm(count > 0);

        count = products.filter((p: PensionProduct) => p.productTransfer && p.productTransfer.status === TransferStatuses.StartTransfer).length;
        setHasProductsToTransfer(count > 0);
    }, [products]);

    const SavingsColumn = (params: any) => {
        const product = params.row.original;
        const { productTransfer } = product;

        let str = '';
        const getSavingsAmount = () => {
            if (productTransfer?.potentialSavings && productTransfer?.potentialSavings > 0) {
                str = addCommas(productTransfer?.potentialSavings);
            } else if (productTransfer?.peakSavingsDiff && productTransfer?.peakSavingsDiff > 0) {
                const year = new Date().getFullYear();
                str = `${addCommas(productTransfer.peakSavingsDiff)} (${year}-${year + productTransfer.optimalSavingsYears})`;
            }

            return str;
        };

        if (product.isNewProduct) return <div className="badge font-14 bg-warning p-1">New product</div>;
        else {
            return (
                <div className="d-flex flex-column justify-content-center align-items-center" onClick={() => onProductSelected(params.row.original)}>
                    ₪{getSavingsAmount()}
                </div>
            );
        }
    };

    const changeableStatuses = [TransferStatuses.StartTransfer, TransferStatuses.Initial];

    const onStatusChanged = async (e: any, product: PensionProduct) => {
        const status = e.target.value;

        if (!changeableStatuses.includes(product.productTransfer.status) || !changeableStatuses.includes(status)) return;

        try {
            await editProductTransfer(product.productTransfer._id, { status });
            const tmpProducts = [...products];
            const index = tmpProducts.findIndex((p) => p._id === product._id);
            tmpProducts[index].productTransfer.status = status;
            setProducts(tmpProducts);
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    const deleteNewProduct = async (product: PensionProduct) => {
        if (!window.confirm('Are you sure you want to delete this product?')) return;

        try {
            await removeProduct(product._id);
            setProducts(products.filter((p) => p._id !== product._id));
        } catch (err: any) {
            if (err.status === 402) alert("Product transfer status must be 'initial' in order to delete");

            globalErrorHandler(err);
        }
    };

    const StatusColumn = (params: any) => {
        const product = params.row.original;
        if (product.isNewProduct && TransferStatuses.Initial === product.productTransfer.status) {
            return (
                <Button className="btn btn-warning" onClick={() => deleteNewProduct(product)}>
                    Delete
                </Button>
            );
        }
        return (
            <div
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                }}
            >
                {changeableStatuses.includes(params.cell.value) ? (
                    <FormInput
                        label=""
                        defaultValue={params.cell.value}
                        type="select"
                        name="task-status"
                        placeholder="Status"
                        options={[
                            { label: 'initial', value: TransferStatuses.Initial },
                            {
                                label: 'start transfer',
                                value: TransferStatuses.StartTransfer,
                            },
                        ]}
                        onSelected={(e: any) => onStatusChanged(e, params.row.original)}
                        // onInputChange={onInputChange}
                    />
                ) : (
                    <span>{camelCaseToText(params.cell.value)}</span>
                )}
            </div>
        );
    };

    const ProductColumn = (params: any) => {
        const [showEditProductTransfer, setShowEditProductTransfer] = useState(false);
        const [product, setProduct] = useState<PensionProduct>(params.row.original);
        const [investmentPolicies, setInvestmentPolicies] = useState<string[]>([]);

        useEffect(() => {
            setProduct(params.row.original);
        }, [params.row.original]);

        const onSaveProductTransfer = async (productTransfer: ProductTransfer) => {
            const updatedProducts = [...products];
            const index = updatedProducts.findIndex((p) => p.productTransfer?._id === productTransfer._id);
            updatedProducts[index].productTransfer = productTransfer;
            setProducts(updatedProducts);
            setShowEditProductTransfer(false);
        };

        useEffect(() => {
            // sum the total savings over all the policies
            const total = product.investmentPolicies.reduce((acc, policy) => acc + policy.amount, 0);

            const list = product.investmentPolicies
                .map((policy: any) => ({
                    policy: policy.fund.investmentPolicy,
                    percent: (policy.amount / total) * 100,
                }))
                .filter((item) => item.percent > 10);

            setInvestmentPolicies(Array.from(new Set<string>(list.map((item: any) => item.policy))));
        }, [product]);

        const [showModal, setShowModal] = useState(false);

        return (
            <>
                {/*<div>{camelCaseToText(product.productType).split(" ").join("\n")}</div>*/}
                {/*<div>{product.company?.name}</div>*/}
                {/*<div>{product.policyNumber}</div>*/}

                <div className="from-to-table">
                    <div className="from" onClick={() => onProductSelected(product)}>
                        <span className="company-name">{getCompanyName((product.company as Company)?.name)}</span>
                        <span className="product-type">{camelCaseToText(product.productType).split(' ').join('\n')}</span>

                        <span className="investment-policies">
                            {investmentPolicies.map((item: any) => (
                                <span className="item">{item}</span>
                            ))}
                        </span>

                        <span className="policy-number">{product.policyNumber?.replace(/^0+/, '')}</span>

                        <span className="commission">
                            {product.commissions.percentages.deposit ? product.commissions.percentages.deposit + '%' : '0%'}
                        </span>
                        <span className="commission">
                            {product.commissions.percentages.savings ? product.commissions.percentages.savings + '%' : '0%'}
                        </span>
                    </div>
                    {product.productTransfer ? (
                        <div className="to bg-soft-success" onClick={() => setShowEditProductTransfer(true)}>
                            <span className="company-name">{getCompanyName((product.productTransfer.company as Company)?.name)}</span>
                            <span className="product-type">{camelCaseToText(product.productTransfer.productType).split(' ').join('\n')}</span>
                            <span className="investment-policies">
                                {product.productTransfer.investmentPolicies.map((item: any) => (
                                    <span className="item">{item.fund.investmentPolicy}</span>
                                ))}
                            </span>
                            <span className="policy-number"></span>
                            <span className="commission">{product.productTransfer.commissions.deposit}%</span>
                            <span className="commission">{product.productTransfer.commissions.savings}%</span>
                        </div>
                    ) : null}
                </div>

                {product && product.productTransfer && showEditProductTransfer ? (
                    <EditProductTransfer
                        onHide={() => setShowEditProductTransfer(false)}
                        show={showEditProductTransfer}
                        onSave={onSaveProductTransfer}
                        product={product}
                        productTransfer={product.productTransfer}
                    />
                ) : null}
            </>
        );
    };

    const ActiveColumn = (params: any) => {
        const product = params.row.original;

        return <div className="d-flex justify-content-center">{product.productTransfer?.isActive || product.isActiveByDeposits ? 'Yes' : 'No'}</div>;
    };

    const DepositColumn = (params: any) => {
        const product = params.row.original;
        let expectedDeposit: number = 0;
        if (product.productTransfer) {
            const { salary, employerDepositPercentage, employeeDepositPercentage, severancePercentage } = product.productTransfer;
            expectedDeposit = (salary * ((employerDepositPercentage || 0) + (employeeDepositPercentage || 0) + (severancePercentage || 0))) / 100;
        }
        return (
            <div className="mx-3">
                <div className="d-flex justify-content-between">
                    <div style={{ width: '100px' }}>₪{addCommas(product.deposits.averageMonthlyDeposit)}</div>
                    <div className="">{formatDate(product.deposits.lastDepositDate, true, false)}</div>
                </div>
                {!!expectedDeposit && (
                    <div className="d-flex justify-content-between">
                        <div style={{ width: '100px' }}>₪{addCommas(expectedDeposit)}</div>
                        <div className="">New Deposit</div>
                    </div>
                )}
            </div>
        );
    };

    const WarningsColumn = (params: any) => {
        const isSpecialEducationChildProduct = (product: PensionProduct) => {
            if (!client.specialEducationChild) return false;

            if (!product.isActiveByDeposits) return false;

            if (
                product.productType === PensionProductTypes.comprehensivePensionFund ||
                product.productType === PensionProductTypes.complementaryPensionFund
            )
                return true;

            if (product.productType === PensionProductTypes.directorsInsurance && product.insurance.workDisability.length) return true;

            return false;
        };

        const product = params.row.original;

        return (
            <div className="d-flex justify-content-center">
                {product.loans.length ? <div className="badge font-14 bg-danger p-1">Active Loan</div> : null}
                {product.isShortTermStudyFund ? <div className="badge font-14 bg-danger p-1">Short Term Study Fund</div> : null}
                {product.sameAgentAndPensionOperator ? (
                    <div className="badge font-13 bg-warning p-1" style={{ lineHeight: '1.3' }}>
                        {' '}
                        Same Agent and <br /> Pension Operator
                    </div>
                ) : null}
                {isSpecialEducationChildProduct(product) ? <div className="badge font-14 bg-danger p-1">Disabled Child</div> : null}
                {product.hasActiveInsuranceClaim && <div className="badge font-14 bg-danger p-1">Active Insurance Claim</div>}
            </div>
        );
    };

    const determineRowStyle = (row: any) => {
        const product = row.original;

        if (isProductHandledByBetter(product)) {
            return { borderLeft: '8px solid #4d78cd' };
        }
        return {};
    };

    const columns = [
        {
            Header: 'Product / Investment Policy / Policy Number / Commissions',
            accessor: 'productType',
            classes: 'table-client',
            Cell: ProductColumn,
        },
        {
            Header: 'Potential Savings',
            accessor: 'productTransfer.potentialSavings',
            classes: 'table-client',
            Cell: SavingsColumn,
        },
        {
            Header: 'Active',
            accessor: 'productTransfer.abc',
            classes: 'table-client',
            Cell: ActiveColumn,
        },
        {
            Header: 'Warnings',
            accessor: 'productTransfer.isActive',
            classes: 'table-client',
            Cell: WarningsColumn,
        },
        {
            Header: 'Last deposit',
            accessor: 'deposits.lastDepositDate',
            classes: 'table-client',
            Cell: DepositColumn,
        },
        {
            Header: 'Savings',
            accessor: 'totalSavings',
            classes: 'table-client',
            Cell: MoneyColumn,
        },
        {
            Header: 'Employment',
            accessor: 'employmentStatus',
            classes: 'table-client',
            Cell: (params: any) => <div className="d-flex justify-content-center">{camelCaseToText(params.cell.value)}</div>,
        },
        {
            Header: 'Fund transfer',
            accessor: 'productTransfer.status',
            classes: 'table-client',
            Cell: StatusColumn,
        },
    ];

    const onProductSelected = (product: PensionProduct) => {
        // const url = window.URL.createObjectURL('/product?productId='+product._id);
        // const link = document.createElement("a");
        // link.href = url;
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode?.removeChild(link);
        // navigate('/product?productId=' + product._id, {product});
        window.open('/product?productId=' + product._id, '_blank');
    };

    const getLessProducts = (prods: PensionProduct[]) => prods.splice(0, MAX_SHOWN_PRODUCTS);

    const handleProducts = async () => {
        if (!pension) return;

        const params = { pensionId: pension._id };

        try {
            const results = await getProducts(params);

            setHanmakaSignedDocument(results.hanmakaSignedDocument);
            setTransfersSignedDocument(results.transfersSignedDocument);

            setProducts([...results.products]);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const viewAllProducts = () => setProducts(allProducts.length !== products.length ? allProducts : getLessProducts(allProducts));

    const downloadCubes = async () => {
        if (!pension) return;

        try {
            const result = await downloadCubeForm(pension._id);
            const blob = await result.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `${t('cubes form')} ${client.firstName} ${client.lastName} ${client.idNumber}.docx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (err: any) {
            if (err.status === 400) alert("Can't download cubes doc - all products are inactive");

            globalErrorHandler(err);
        }
    };

    const recalculateSavingsClick = async () => {
        setIsLoadingUpdateCalc(true);

        try {
            await recalculateSavings({ clientId: client._id });
            handleProducts();
            setIsLoadingUpdateCalc(false);
        } catch (err: any) {
            setIsLoadingUpdateCalc(false);
            globalErrorHandler(err);
        }
    };

    const generateTransfersSignedDocument = async (preview: boolean) => {
        try {
            const result = await getMultipleDocsSigningUrl(client._id, pension?._id, preview);
            if (preview) return result;

            await handleProducts();

            return result.signedDocument;
        } catch (err: any) {
            if (err.status === 402) alert('Missing employer details');
            else if (err.status === 406) alert('The following product has loans: ' + err.extraData);
            else if (err.status === 405) {
                debugger;
                if (window.confirm('Fill KYC form first')) {
                    window.open(err.extraData?.jotformLink, '_blank');
                }
            } else if (err.status === 408) {
                alert("Special child - can't transfer fund");
            } else if (err.status === 411) {
                alert('No product is status start transfer');
            } else {
                globalErrorHandler(err);
            }
        }
    };

    const generateHanmaka = async (preview: boolean) => {
        const result = await getFullHanmaka(client._id, preview);
        if (preview) return result;

        return result.signedDocument;
    };

    const onError = (err: any) => {
        alert(err.message || err);
    };

    const createNewProduct = async (newProduct: NewPensionProduct) => {
        newProduct.pensionId = pension?._id;

        try {
            const result = await createProduct(newProduct);
            setProducts([result.product].concat(products));
            setShowNewProduct(false);
            navigate('/product?productId=' + result.product._id);
        } catch (err: any) {
            if (err.status === 406)
                alert(
                    `Investment policy '${newProduct.investmentPolicy}' does not exist for the product type ${newProduct.productType} with this company`,
                );
            else {
                globalErrorHandler(err);
            }
        }
    };

    const handleJotFetchClick = async (jotformType: string) => {
        try {
            const result = await fetchJotformSubmissionManually(client._id, jotformType);
            alert('Jotform fetched successfully');
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const archiveSigningDocument = async () => {
        if (!transfersSignedDocument) return;

        if (!window.confirm('Are you sure you want to archive this document?')) return;

        try {
            const result = await archiveSignature(transfersSignedDocument._id);
            setTransfersSignedDocument(undefined);
            await handleProducts();
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    const handleUpdateProducts = async () => {
        setUpdatingProductsLoader(true);
        try {
            await updateProducts(client?._id);
            setProductsDataForPensionZoomIsAvailable(true);
            handleReloadPopup();
        } catch (err) {
            globalErrorHandler(err);
        } finally {
            setUpdatingProductsLoader(false);
        }
    };

    const handleGenerateZoomPage = () => {
        const { token } = getUserFromCookie();
        const clientId = client?._id;
        if (!clientId || !token) return;
        const popup = window.open(`${PENSION_ZOOM_WEBSITE_URL}/?token=${token}&clientId=${clientId}`, '_blank');
        setPensionZoomWindow(popup);
    };

    const sendCubesToEmployerButtonDisabilityStatusAndText = () => {
        const selectedModule = profileContextData.selectedModule;
        const allowedStatusesOptions = selectedModule && selectedModule.allowedStatuses ? Object.values(selectedModule.allowedStatuses) : [];
        const relevantStatuses = allowedStatusesOptions.slice(allowedStatusesOptions.indexOf('agentSignedForms'));
        const currentStatus = pension?.status;
        if (!relevantStatuses.includes(currentStatus))
            return {
                isDisabled: true,
                text: 'Not the right status for sending cubes',
            };
        if (pension?.isCubesDocSentToEmployer) return { isDisabled: true, text: 'Cubes sent to employer \u2705' };
        return { isDisabled: false, text: 'Send cubes to employer' };
    };

    const handleReloadPopup = () => {
        if (pensionZoomWindow && !pensionZoomWindow.closed) {
            pensionZoomWindow.postMessage('reload', PENSION_ZOOM_WEBSITE_URL);
        }
    };

    return (
        <Card className="profile-products">
            <Card.Body>
                <div className="">
                    <div className="row">
                        <h4 className="header-title mb-3">Products</h4>
                    </div>
                    <div className="buttons">
                        <Button className="btn btn-primary" onClick={() => setShowNewProduct(true)}>
                            {t('new product')}
                        </Button>
                        <Button className="right pointer" onClick={() => downloadCubes()}>
                            <i className="mdi mdi-download"></i> {t('cubes form')}
                        </Button>
                        <Button className="btn btn-primary" onClick={(e) => handleJotFetchClick('jotFormKYC')}>
                            Fetch KYC Jotform
                        </Button>
                        <div>
                            <div className="double-item">
                                <DualSigningButton
                                    text={t('forms for signing')}
                                    PreGenerate={CubesModal}
                                    signedDocument={transfersSignedDocument}
                                    isEnabled={hasProductsToTransfer}
                                    generateSigningLink={generateTransfersSignedDocument}
                                    onArchive={transfersSignedDocument ? archiveSigningDocument : undefined}
                                    onError={onError}
                                />
                            </div>
                        </div>
                        <div className="double-item">
                            <DualSigningButton
                                text={t('hanmmaka to client')}
                                signedDocument={hanmakaSignedDocument}
                                isEnabled={products.length > 0}
                                generateSigningLink={generateHanmaka}
                                onError={onError}
                            />
                        </div>
                        <LaddaButton
                            loading={isLoadingUpdateCalc}
                            //  data-style={EXPAND_LEFT}
                            className="btn btn-primary"
                            dir="ltr"
                            onClick={() => recalculateSavingsClick()}
                        >
                            Recalculate Savings {!isLoadingUpdateCalc && <i className="mdi mdi-reload"></i>}
                        </LaddaButton>
                        <div className="">
                            <LaddaButton
                                loading={updatingProductsLoader}
                                data-style={EXPAND_LEFT}
                                className="btn btn-primary"
                                dir="ltr"
                                onClick={handleUpdateProducts}
                            >
                                Update Products {!updatingProductsLoader && <i className="mdi mdi-reload"></i>}
                            </LaddaButton>

                            <Button disabled={!productsDataForPensionZoomIsAvailable} className="btn btn-primary" onClick={handleGenerateZoomPage}>
                                Open pension zoom
                            </Button>
                        </div>

                        {pension?._id && (
                            <SendCubesToEmployerButton pensionId={pension._id} {...sendCubesToEmployerButtonDisabilityStatusAndText()} />
                        )}
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={products}
                    pageSize={50}
                    rowNumbering={true}
                    rowStyle={determineRowStyle}
                    // rowNumbering={(row: any) => betterLogoToProductsHandledByBetter(row)}
                    isSortable={true}
                    pagination={false}
                    isSelectable={false}
                    tableClass="table-nowrap table-striped products-table"
                />

                {showNewProduct ? <CreateNewPensionProduct onSave={createNewProduct} show={true} onHide={() => setShowNewProduct(false)} /> : null}
            </Card.Body>
        </Card>
    );
};

export default Products;
